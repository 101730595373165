<template>
  <div class="partition-form van-common-detail-button">
    <van-form ref="appForm" :input-align="constants.inputAlign" label-width="110px">
      <div class="area-title">
        <p class="title">
          货物信息
        </p>
      </div>
      <van-cell title="申请提货日期：" :value="utils.dataString(appForm.applyDate)" />
      <van-cell title="柜号：" :value="utils.isEffectiveCommon(appForm.cabinetNo)" />
      <van-cell title="货物名称：" :value="utils.isEffectiveCommon(appForm.cargoName)" />
      <van-cell title="提货方式：" :value="appForm.cargoType===1? '按件提货':'按重量提货'" />
      <div class="table goods-table">
        <table class="inner-goods-table">
          <thead>
            <tr>
              <th>品名</th>
              <th>认定单价（元）</th>
              <th>件数</th>
              <th>货款（元）</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in appForm.goodsList" :key="item.id">
              <td>{{ utils.isEffectiveCommon(item.productName) }}</td>
              <td>{{ utils.isEffectiveCommon(item.packageUnitPrice) }}</td>
              <td>{{ utils.isEffectiveCommon(item.applyNumber) }}</td>
              <td>{{ utils.isEffectiveCommon(NP.times(item.packageUnitPrice,item.applyNumber)) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <van-cell title="合计：" :value="utils.isEffectiveCommon(totalMoney)" />
      <div class="area-title">
        <p class="title">
          装车信息
        </p>
      </div>
      <van-cell title="司机姓名：" :value="utils.isEffectiveCommon(appForm.vehiclesInfoVo.contacts)" />
      <van-cell title="电话：" :value="utils.isEffectiveCommon(appForm.vehiclesInfoVo.phone)" />
      <van-cell title="身份证号：" :value="utils.isEffectiveCommon(appForm.vehiclesInfoVo.certificates)" />
      <van-cell title="车牌号：" :value="utils.isEffectiveCommon(appForm.vehiclesInfoVo.numberPlates)" />
      <van-cell title="备注：" :value="utils.isEffectiveCommon(appForm.vehiclesInfoVo.remarks)" />
      <div class="button-fixed">
        <van-button type="info" class="button-fixed-margin" block @click="submit">
          提交
        </van-button>
        <van-button type="info" block @click="$router.push({path:'/delivery/apply'})">
          返回
        </van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
import { List, PullRefresh, Cell, CellGroup, Search, Button, Row, Col, Form, Field, Popup, Picker, Uploader, SwipeCell, Toast, Calendar, Checkbox, CheckboxGroup, RadioGroup, Radio, DatetimePicker } from 'vant'

export default {
  components: {
    [DatetimePicker.name]: DatetimePicker,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    [CheckboxGroup.name]: CheckboxGroup,
    [Checkbox.name]: Checkbox,
    [Calendar.name]: Calendar,
    [Toast.name]: Toast,
    [List.name]: List,
    [PullRefresh.name]: PullRefresh,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Search.name]: Search,
    [Button.name]: Button,
    [Row.name]: Row,
    [Col.name]: Col,
    [Form.name]: Form,
    [Field.name]: Field,
    [Popup.name]: Popup,
    [Picker.name]: Picker,
    [Uploader.name]: Uploader,
    [SwipeCell.name]: SwipeCell
  },
  data () {
    return {
      stockContainerId: this.$route.query.stockContainerId,
      showApplyDate: false,
      currentDate: new Date(),
      accountForm: {},
      showGoods: false,
      detailList: [],
      totalNumber: '',
      totalMoney: '',
      appForm: {
        applyNo: '',
        applyDate: '',
        cabinetNo: '',
        cargoName: '',
        money: '',
        extractableNumber: '',
        warehouseName: '',
        extractableWeight: '',
        actualPieceNumber: '',
        actualWeight: '',
        vehiclesInfoVo: {},
        containerInfoVo: {},
        goodsList: []
      },
      finishedText: '没有更多了',
      page: 1,
      tableList: [],
      loading: {
        detail: false
      },
      finished: false,
      refreshing: false,
      loadingSubmit: false
    }
  },
  created () {
    this.getDetail()
  },
  methods: {
    // 获取详情
    getDetail () {
      this.appForm = JSON.parse(sessionStorage.getItem('deliveryForm'))
      this.applyNumberBlur()
    },
    applyNumberBlur () {
      this.totalNumber = 0
      this.totalMoney = 0
      this.appForm.goodsList.forEach(item => {
        const date = this._.toNumber(item.applyNumber)
        if (this._.isNumber(date) && !this._.isNaN(date)) {
          this.totalNumber += date
          if (this.appForm.cargoType === 1) {
            this.totalMoney += this.NP.times(date, this._.toNumber(item.packageUnitPrice))
          } else {
            this.totalMoney += this.NP.times(date, this._.toNumber(item.unitPrice))
          }
        }
      })
    },
    // 提交
    submit () {
      const deliveryForm = JSON.parse(sessionStorage.getItem('deliveryForm'))
      this.api.delivery.packOutAdd(deliveryForm).then(result => {
        Toast.success(result.data.message || '提交成功')
        this.$router.push({ path: '/delivery/apply' })
      }).finally(() => {
      })
    }
  }
}
</script>

<style>
</style>
