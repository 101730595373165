var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "partition-form van-common-detail-button" },
    [
      _c(
        "van-form",
        {
          ref: "appForm",
          attrs: {
            "input-align": _vm.constants.inputAlign,
            "label-width": "110px"
          }
        },
        [
          _c("div", { staticClass: "area-title" }, [
            _c("p", { staticClass: "title" }, [_vm._v(" 货物信息 ")])
          ]),
          _c("van-cell", {
            attrs: {
              title: "申请提货日期：",
              value: _vm.utils.dataString(_vm.appForm.applyDate)
            }
          }),
          _c("van-cell", {
            attrs: {
              title: "柜号：",
              value: _vm.utils.isEffectiveCommon(_vm.appForm.cabinetNo)
            }
          }),
          _c("van-cell", {
            attrs: {
              title: "货物名称：",
              value: _vm.utils.isEffectiveCommon(_vm.appForm.cargoName)
            }
          }),
          _c("van-cell", {
            attrs: {
              title: "提货方式：",
              value: _vm.appForm.cargoType === 1 ? "按件提货" : "按重量提货"
            }
          }),
          _c("div", { staticClass: "table goods-table" }, [
            _c("table", { staticClass: "inner-goods-table" }, [
              _c("thead", [
                _c("tr", [
                  _c("th", [_vm._v("品名")]),
                  _c("th", [_vm._v("认定单价（元）")]),
                  _c("th", [_vm._v("件数")]),
                  _c("th", [_vm._v("货款（元）")])
                ])
              ]),
              _c(
                "tbody",
                _vm._l(_vm.appForm.goodsList, function(item) {
                  return _c("tr", { key: item.id }, [
                    _c("td", [
                      _vm._v(
                        _vm._s(_vm.utils.isEffectiveCommon(item.productName))
                      )
                    ]),
                    _c("td", [
                      _vm._v(
                        _vm._s(
                          _vm.utils.isEffectiveCommon(item.packageUnitPrice)
                        )
                      )
                    ]),
                    _c("td", [
                      _vm._v(
                        _vm._s(_vm.utils.isEffectiveCommon(item.applyNumber))
                      )
                    ]),
                    _c("td", [
                      _vm._v(
                        _vm._s(
                          _vm.utils.isEffectiveCommon(
                            _vm.NP.times(
                              item.packageUnitPrice,
                              item.applyNumber
                            )
                          )
                        )
                      )
                    ])
                  ])
                }),
                0
              )
            ])
          ]),
          _c("van-cell", {
            attrs: {
              title: "合计：",
              value: _vm.utils.isEffectiveCommon(_vm.totalMoney)
            }
          }),
          _c("div", { staticClass: "area-title" }, [
            _c("p", { staticClass: "title" }, [_vm._v(" 装车信息 ")])
          ]),
          _c("van-cell", {
            attrs: {
              title: "司机姓名：",
              value: _vm.utils.isEffectiveCommon(
                _vm.appForm.vehiclesInfoVo.contacts
              )
            }
          }),
          _c("van-cell", {
            attrs: {
              title: "电话：",
              value: _vm.utils.isEffectiveCommon(
                _vm.appForm.vehiclesInfoVo.phone
              )
            }
          }),
          _c("van-cell", {
            attrs: {
              title: "身份证号：",
              value: _vm.utils.isEffectiveCommon(
                _vm.appForm.vehiclesInfoVo.certificates
              )
            }
          }),
          _c("van-cell", {
            attrs: {
              title: "车牌号：",
              value: _vm.utils.isEffectiveCommon(
                _vm.appForm.vehiclesInfoVo.numberPlates
              )
            }
          }),
          _c("van-cell", {
            attrs: {
              title: "备注：",
              value: _vm.utils.isEffectiveCommon(
                _vm.appForm.vehiclesInfoVo.remarks
              )
            }
          }),
          _c(
            "div",
            { staticClass: "button-fixed" },
            [
              _c(
                "van-button",
                {
                  staticClass: "button-fixed-margin",
                  attrs: { type: "info", block: "" },
                  on: { click: _vm.submit }
                },
                [_vm._v(" 提交 ")]
              ),
              _c(
                "van-button",
                {
                  attrs: { type: "info", block: "" },
                  on: {
                    click: function($event) {
                      return _vm.$router.push({ path: "/delivery/apply" })
                    }
                  }
                },
                [_vm._v(" 返回 ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }